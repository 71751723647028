import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import JwtService from "@/core/services/JwtService";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/dashboard",
    redirect: "/dashboard",
    component: () => import("@/layout/Layout.vue"),
    children: [
      { path: "/dashboard", name: "dashboard", component: () => import("@/views/Dashboard.vue"),},
      // { path: "/chat", component: () => import("@/views/profile/chat/chat.vue"),
      //   children:[
      //     { path:"/room/:id",name:"room_chat",component:()=>import("@/views/profile/chat/chatRoom.vue")},
      //   ]
      // },
      { path: "/myProfile", name: "my_profile", component: () => import("@/views/profile/myProfile.vue"),},//test
      { path: "/users", name: "users", component: () => import("@/views/users.vue"),},
      { path: "/store/:id", name: "store_profile", component: () => import("@/views/storeProfile.vue"),},
      { path: "/stores", name: "stores", component: () => import("@/views/stores.vue"),},
      { path: "/createProduct", name: "product", component: () => import("@/views/profile/crud/products/createProduct.vue"),},
      { path: "/:name/:id", name: "product_detail", component: () => import("@/views/detailViews/productDetail.vue"),},
      { path: "/editProduct/:id", name: "edit_product", component: () => import("@/views/profile/crud/products/editProduct.vue"),},
      { path: "/createEvent", name: "create_event", component: () => import("@/views/profile/crud/events/createEvent.vue"),},
      { path: "/event/:id", name: "event", component: () => import("@/views/GAMES/event.vue"),},
      { path: "/insertResult/:id", name: "insert_result", component: () => import("@/views/profile/crud/events/insertResult.vue"),},
      { path: "/editEvent/:id", name: "edit_event", component: () => import("@/views/profile/crud/events/editEvent.vue"),},
      { path: "/user/:id", name:"user_profile", component: () => import("@/views/userProfile/user.vue"),},
      { path: "/contact", name: "contact", component: () => import("@/views/contact.vue"),},
      { path: "/stores/region/", name: "views-stores-region", component: () => import("@/views/storesRegion.vue"),},
      { path: "/profile", redirect:"/profile/posts", component: () => import("@/views/profile/profile.vue"),
        children:[
          { path: "/profile/posts", name: "my-profile", component: () => import("@/views/profile/posts.vue"),},
          { path: "/profile/events", name: "my-events", component: () => import("@/views/profile/events.vue"),},
          { path: "/profile/products", name: "my-products", component: () => import("@/views/profile/products.vue"),},
          { path: "/profile/community", name: "my-community", component: () => import("@/views/profile/community.vue"),},
        ]
      },
      { path: "/Yu-Gi-Oh", name:"Yu-Gi-Oh", component: () => import("@/views/GAMES/YUGIOH/layout-index-ygo.vue"),},
      { path: "/TournamentCalendar/:name/:id", name:"calendar_tournament", component: () => import("@/views/GAMES/GameCalendarRegion.vue"),},
      { path: "/createDeck/:id", name:"create_deck", component: () => import("@/views/GAMES/createDeck.vue"),},
      { path: "/learning/Yu-Gi-Oh", name:"learning-ygo", component: () => import("@/views/GAMES/YUGIOH/learning.vue"),},
      { path: "/Magic-The-Gathering", name:"Magic-The-Gathering", component: () => import("@/views/GAMES/MAGIC/layout-index-mtg.vue"),},
      { path: "/learning/Magic-The-Gathering", name:"learning-mtg", component: () => import("@/views/GAMES/MAGIC/learning.vue"),},
      { path: "/Cardfight-Vanguard", name:"Cardfight-Vanguard", component: () => import("@/views/GAMES/VANGUARD/layout-index-cfv.vue"),},
      { path: "/learning/Cardfight-Vanguard", name:"learning-cfv", component: () => import("@/views/GAMES/VANGUARD/learning.vue"),},
      { path: "/Pokemon", name:"Pokemon", component: () => import("@/views/GAMES/POKEMON/layout-index-pkm.vue"),},
      { path: "/learning/Pokemon", name:"learning-pkm", component: () => import("@/views/GAMES/POKEMON/learning.vue"),},
      { path: "/Keyforge", name:"Keyforge", component: () => import("@/views/GAMES/KEYFORGE/layout-index-key.vue"),},
      { path: "/learning/Keyforge", name:"learning-key", component: () => import("@/views/GAMES/KEYFORGE/learning.vue"),},
      { path: "/communityDiFiducia/:id", name: "trusted_group", component: () => import("@/views/detailViews/trustedGroup.vue"),},
      { path: "/tournamentHistoryPlayer/:id/game/:game_id", name: "history_tournament", component: () => import("@/views/detailViews/tournament_history.vue"),},
      { path: "/myCalendar", name: "my_calendar", component: () => import("@/components/calendar/CalendarApp1.vue"),},
      { path: "/admin", redirect:"/admin/counters", component: () => import("@/views/admin/views/dashboard.vue"),
        children:[
          { path: "/admin/counters", name: "admin_counters", component: () => import("@/views/admin/views/counters.vue"),},
          { path: "/admin/players", name: "admin_players", component: () => import("@/views/admin/views/players.vue"),},
          { path: "/admin/stores", name: "admin_stores", component: () => import("@/views/admin/views/stores.vue"),},
          { path: "/admin/company", name: "admin_company", component: () => import("@/views/admin/views/company.vue"),},
          { path: "/admin/employees", name: "admin_employee", component: () => import("@/views/admin/views/employee.vue"),},
          // { path: "/admin/posts", name: "admin_posts", component: () => import("@/views/admin/views/posts.vue"),},
          { path: "/admin/events", name: "admin_events", component: () => import("@/views/admin/views/events.vue"),},
          { path: "/admin/tournaments", name: "admin_tournaments", component: () => import("@/views/admin/views/tournaments.vue"),},
          { path: "/admin/products", name: "admin_products", component: () => import("@/views/admin/views/products.vue"),},
        ]
      },
      // settings
      { path: "/user/settings", name: "user_settings", component: () => import("@/views/profile/settingsUser.vue"),},
      { path: "/admin/conditions", name: "admin_conditions", component: () => import("@/views/admin/views/settings/conditions.vue"),},
      { path: "/admin/visibilities", name: "admin_visibilities", component: () => import("@/views/admin/views/settings/visibilities.vue"),},
      { path: "/admin/games", name: "admin_games", component: () => import("@/views/admin/views/settings/games.vue"),},
      { path: "/admin/regions", name: "admin_regions", component: () => import("@/views/admin/views/settings/regions.vue"),},
      { path: "/admin/seasons", name: "admin_seasons", component: () => import("@/views/admin/views/settings/seasons.vue"),},
      { path: "/admin/region/:id", name: "admin_region_details", component: () => import("@/views/admin/views/regionDetail.vue"),},
      {
        path: "/builder",
        name: "builder",
        component: () => import("@/views/Builder.vue"),
      },
      {
        path: "/crafted/pages/profile",
        name: "profile",
        component: () => import("@/components/page-layouts/Profile.vue"),
        children: [
          {
            path: "overview",
            name: "profile-overview",
            component: () =>
              import("@/views/crafted/pages/profile/Overview.vue"),
          },
          {
            path: "projects",
            name: "profile-projects",
            component: () =>
              import("@/views/crafted/pages/profile/Projects.vue"),
          },
          {
            path: "campaigns",
            name: "profile-campaigns",
            component: () =>
              import("@/views/crafted/pages/profile/Campaigns.vue"),
          },
          {
            path: "documents",
            name: "profile-documents",
            component: () =>
              import("@/views/crafted/pages/profile/Documents.vue"),
          },
          {
            path: "connections",
            name: "profile-connections",
            component: () =>
              import("@/views/crafted/pages/profile/Connections.vue"),
          },
          {
            path: "activity",
            name: "profile-activity",
            component: () =>
              import("@/views/crafted/pages/profile/Activity.vue"),
          },
        ],
      },
      {
        path: "/crafted/pages/wizards/horizontal",
        name: "horizontal-wizard",
        component: () => import("@/views/crafted/pages/wizards/Horizontal.vue"),
      },
      {
        path: "/crafted/pages/wizards/vertical",
        name: "vertical-wizard",
        component: () => import("@/views/crafted/pages/wizards/Vertical.vue"),
      },
      {
        path: "/crafted/account",
        name: "account",
        component: () => import("@/views/crafted/account/Account.vue"),
        children: [
          {
            path: "overview",
            name: "account-overview",
            component: () => import("@/views/crafted/account/Overview.vue"),
          },
          {
            path: "settings",
            name: "account-settings",
            component: () => import("@/views/crafted/account/Settings.vue"),
          },
        ],
      },
      {
        path: "/apps/customers/getting-started",
        name: "apps-customers-getting-started",
        component: () => import("@/views/apps/customers/GettingStarted.vue"),
      },
      {
        path: "/apps/customers/customers-listing",
        name: "apps-customers-listing",
        component: () => import("@/views/apps/customers/CustomersListing.vue"),
      },
      {
        path: "/apps/customers/customer-details",
        name: "apps-customers-details",
        component: () => import("@/views/apps/customers/CustomerDetails.vue"),
      },
      {
        path: "/apps/subscriptions/getting-started",
        name: "apps-subscriptions-getting-started",
        component: () =>
          import("@/views/apps/subscriptions/GettingStarted.vue"),
      },
      {
        path: "/apps/subscriptions/subscription-list",
        name: "apps-subscriptions-subscription-list",
        component: () =>
          import("@/views/apps/subscriptions/SubscriptionList.vue"),
      },
      {
        path: "/apps/subscriptions/add-subscription",
        name: "apps-subscriptions-add-subscription",
        component: () =>
          import("@/views/apps/subscriptions/AddSubscription.vue"),
      },
      {
        path: "/apps/subscriptions/view-subscription",
        name: "apps-subscriptions-view-subscription",
        component: () =>
          import("@/views/apps/subscriptions/ViewSubscription.vue"),
      },
      {
        path: "/apps/calendar",
        name: "apps-calendar",
        component: () => import("@/views/apps/Calendar.vue"),
      },
      {
        path: "/apps/chat/private-chat",
        name: "apps-private-chat",
        component: () => import("@/views/apps/chat/Chat.vue"),
      },
      {
        path: "/apps/chat/group-chat",
        name: "apps-group-chat",
        component: () => import("@/views/apps/chat/Chat.vue"),
      },
      {
        path: "/apps/chat/drawer-chat",
        name: "apps-drawer-chat",
        component: () => import("@/views/apps/chat/DrawerChat.vue"),
      },
      {
        path: "/crafted/modals/general/invite-friends",
        name: "modals-general-invite-friends",
        component: () =>
          import("@/views/crafted/modals/general/InviteFriends.vue"),
      },
      {
        path: "/crafted/modals/general/view-user",
        name: "modals-general-view-user",
        component: () => import("@/views/crafted/modals/general/ViewUsers.vue"),
      },
      {
        path: "/crafted/modals/general/upgrade-plan",
        name: "modals-general-upgrade-plan",
        component: () =>
          import("@/views/crafted/modals/general/UpgradePlan.vue"),
      },
      {
        path: "/crafted/modals/general/share-and-earn",
        name: "modals-general-share-and-earn",
        component: () =>
          import("@/views/crafted/modals/general/ShareAndEarn.vue"),
      },
      {
        path: "/crafted/modals/forms/new-target",
        name: "modals-forms-new-target",
        component: () => import("@/views/crafted/modals/forms/NewTarget.vue"),
      },
      {
        path: "/crafted/modals/forms/new-card",
        name: "modals-forms-new-card",
        component: () => import("@/views/crafted/modals/forms/NewCard.vue"),
      },
      {
        path: "/crafted/modals/forms/new-address",
        name: "modals-forms-new-address",
        component: () => import("@/views/crafted/modals/forms/NewAddress.vue"),
      },
      {
        path: "/crafted/modals/forms/create-api-key",
        name: "modals-forms-create-api-key",
        component: () =>
          import("@/views/crafted/modals/forms/CreateApiKey.vue"),
      },
      {
        path: "/crafted/modals/wizards/two-factor-auth",
        name: "modals-wizards-two-factor-auth",
        component: () =>
          import("@/views/crafted/modals/wizards/TwoFactorAuth.vue"),
      },
      {
        path: "/crafted/modals/wizards/create-app",
        name: "modals-wizards-create-app",
        component: () => import("@/views/crafted/modals/wizards/CreateApp.vue"),
      },
      {
        path: "/crafted/modals/wizards/create-account",
        name: "modals-wizards-create-account",
        component: () =>
          import("@/views/crafted/modals/wizards/CreateAccount.vue"),
      },
      {
        path: "/crafted/widgets/lists",
        name: "widgets-list",
        component: () => import("@/views/crafted/widgets/Lists.vue"),
      },
      {
        path: "/crafted/widgets/statistics",
        name: "widgets-statistics",
        component: () => import("@/views/crafted/widgets/Statistics.vue"),
      },
      {
        path: "/crafted/widgets/charts",
        name: "widgets-charts",
        component: () => import("@/views/crafted/widgets/Charts.vue"),
      },
      {
        path: "/crafted/widgets/mixed",
        name: "widgets-mixed",
        component: () => import("@/views/crafted/widgets/Mixed.vue"),
      },
      {
        path: "/crafted/widgets/tables",
        name: "widgets-tables",
        component: () => import("@/views/crafted/widgets/Tables.vue"),
      },
      {
        path: "/crafted/widgets/feeds",
        name: "widgets-feeds",
        component: () => import("@/views/crafted/widgets/Feeds.vue"),
      },
    ],
  },
  { path: "/", name: "welcomePage", component: () => import("@/views/welcomePage.vue"),},
  {
    path: "/",
    component: () => import("@/components/page-layouts/Auth.vue"),
    children: [
      
      {
        path: "/about",
        name: "about",
        component: () =>
          import("@/views/about.vue"),
      },
      {
        path: "/termsAndConditions",
        name: "termsAndConditions",
        component: () =>
          import("@/views/termsAndConditions.vue"),
      },
      {
        path: "/account",
        name: "account",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/chooseAccount.vue"),
      },
      {
        path: "/team",
        name: "team",
        component: () =>
          import("@/views/team.vue"),
      },
      {
        path: "/sign-in",
        name: "sign-in",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignIn.vue"),
      },
      {
        path: "/sign-up",
        name: "sign-up",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignUp.vue"),
      },
      {
        path: "/sign-up-store",
        name: "sign-up-store",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignUpStore.vue"),
      },
      {
        path: "/sign-up-player",
        name: "sign-up-player",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignUpPlayer.vue"),
      },
      {
        path: "/password-reset",
        name: "password-reset",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/PasswordReset.vue"),
      },
    ],
  },
  {
    path: "/multi-step-sign-up",
    name: "multi-step-sign-up",
    component: () =>
      import("@/views/crafted/authentication/MultiStepSignUp.vue"),
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/views/crafted/authentication/Error404.vue"),
  },
  {
    path: "/500",
    name: "500",
    component: () => import("@/views/crafted/authentication/Error500.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(() => {
  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);

  store.dispatch(Actions.VERIFY_AUTH, { api_token: JwtService.getToken() });

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

export default router;
